<template>
  <div v-if="canAccess('contratos_read')">
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
      <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <holder :holder="holderData" :edit="false" v-if="!loading"></holder>
    <div class="p-grid">
      <Fieldset legend="Matrículas" :toggleable="true">
        <ProgressBar mode="indeterminate" v-if="loading"/>
        <DataTable :value="enrollments" :filters="filters" scrollable>
          <template #header>
            <Toolbar class="p-col-12 p-mb-1">
              <template #left>
                <h5 class="p-mr-2">
                  Records de producción
                </h5>
              </template>
              <template #right>
                <Button label="Editar titular" icon="pi pi-user" class="p-button-info p-mr-2"
                        @click="editHolder = true, currentItem = null"/>
                <Button label="Nueva matrícula" icon="pi pi-plus" class="p-button-success p-mr-2"
                        @click="editDialog = true, currentItem = null"/>
              </template>
            </Toolbar>
          </template>
          <Column field="sequential" header="N. Matrícula" :sortable="true"></Column>
          <Column field="cloned_info" header="Clonado desde" :sortable="true"></Column>

          <Column field="registration" header="Fecha de verificación" :sortable="true">
            <template #body="slotProps">
              {{ $filters.dateFormat(slotProps.data['registration']) }}
              <span :title="$filters.dateFormat(slotProps.data['relocation_at'])" class="p-badge p-badge-warning p-m-1" v-if="slotProps.data['relocation_at']">
                Reubicada
              </span>
            </template>
          </Column>
          <Column field="plan.name" header="Plan" :sortable="true"></Column>
          <Column field="cost" header="Valor" :sortable="true">
            <template #body="slotProps">
              {{ $filters.moneyFormat(slotProps.data['cost']) }}
            </template>
          </Column>
          <Column field="balance" header="Saldo" :sortable="true">
            <template #body="slotProps">
              {{ $filters.moneyFormat(slotProps.data['balance']) }}
            </template>
          </Column>
          <Column field="status" header="Estado" :sortable="true"></Column>
          <Column field="value_transition" header="Valor en transición" :sortable="true">
            <template #body="slotProps">
              {{ $filters.moneyFormat(slotProps.data['value_transition']) }}
            </template>
          </Column>
          <Column field="employee.name" header="Asesor" :sortable="true">
            <template #body="slotProps">
              {{slotProps.data['employee'].name }} <span class="p-badge p-badge-success p-m-1" v-if="slotProps.data.in_debut===1">debut</span>
            </template>
          </Column>
          <Column field="subsidiary.name" header="Filial" :sortable="true"></Column>

          <Column header="Opciones" headerStyle="width: 230px">
            <template #body="slotProps">
              <Button v-tooltip="'Editar matrícula'" icon="pi pi-pencil"
                      class="p-button-rounded p-button-secondary p-mr-1 p-button-sm" @click="edit(slotProps.data)"/>

              <Button v-tooltip="'Agregar/editar estudiante'" icon="pi pi-user-plus"
                      class="p-button-rounded p-button-info p-mr-1 p-button-sm" @click="editStudent(slotProps.data)"/>

              <Button v-tooltip="'Realizar pagos'" icon="pi pi-money-bill" @click="doPayments(slotProps.data)"
                      class="p-button-rounded p-button-help p-mr-1 p-button-sm"
                      v-if="(slotProps.data.balance-slotProps.data.value_transition)> 0 && slotProps.data.employee?.name!='ASESOR IMPORTACION'"/>

              <Button :disabled="!canMigrate(slotProps.data)" v-tooltip="'Revisar y enviar contrato'"
                      icon="pi pi-external-link" @click="doContract(slotProps.data)"
                      class="p-button-rounded p-button-success p-mr-1 p-button-sm" v-else-if="isContractAvailable"/>

              <Button v-tooltip="'Duplicar Matrícula'" :disabled="slotProps.data.status==='NBS3'" icon="pi pi-clone"
                      @click="confirmClone(slotProps.data)"
                      class="p-button-rounded p-button-success p-mr-1 p-button-sm"/>

              <Button v-tooltip="'Documentos'" icon="pi pi-file" @click="doAddDocs(slotProps.data)"
                      class="p-button-rounded p-button-secondary p-mr-1 p-button-sm"/>

              <Button v-if="isAdmin" icon="pi pi-trash" class="p-button-rounded p-button-warning p-mr-1 p-button-sm"
                      @click="confirmDelete(slotProps.data)" v-tooltip="'Eliminar Registro'"/>

            </template>
          </Column>
        </DataTable>
      </Fieldset>
    </div>
    <Dialog v-model:visible="editDialog" :style="{width: '800px'}" header="Datos de matrícula"
            :modal="true"
            @update:visible="cancelDialog"
            class="p-fluid">
      <enrollment ref="enrollForm" :holder="holderData" :enroll="currentItem" v-if="editDialog"></enrollment>
      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="cancelDialog"/>
        <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveEnroll"/>
      </template>
    </Dialog>
    <Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
        <span v-if="currentItem">Seguro de eliminar registro?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text"
                @click="deleteDialog = false"/>
        <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteRow"/>
      </template>
    </Dialog>
    <Dialog v-model:visible="cloneDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-clone p-mr-3" style="font-size: 2rem"/>
        <span v-if="currentItem">Seguro de querer clonar el registro?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text"
                @click="cloneDialog = false"/>
        <Button label="Si" icon="pi pi-check" class="p-button-text" @click="cloneRow"/>
      </template>
    </Dialog>
    <Sidebar v-model:visible="paymentsSidebar" :baseZIndex="1000" position="right" :style="'width:60%'" @hide="getData">
      <h3>Pagos de matícula</h3>
      <payments ref="paymentList" v-if="currentItem" :enroll="currentItem" :edit="true"></payments>
    </Sidebar>
    <Sidebar v-model:visible="docsSidebar" :baseZIndex="1000" position="right" :style="'width:60%'" @hide="getData">
      <h3>Documentos de matícula</h3>
      <documents ref="documentEnrollmentList" v-if="currentItem" :enroll="currentItem" :edit="true"></documents>
    </Sidebar>
    <Sidebar v-model:visible="studentSidebar" :baseZIndex="1000" position="right" :style="'width:60%'">
      <h3>Estudiantes</h3>
      <students ref="studentList" v-if="currentItem" :enroll="currentItem" :edit="true"></students>
    </Sidebar>

    <Sidebar v-model:visible="contractSidebar" :baseZIndex="1000" position="right" :style="'width:60%'">
      <h3>Contrato</h3>
      <contract ref="studentList" v-if="currentItem" :enroll="currentItem" :edit="true"
                @closeSidebar="contractSidebar = false"></contract>
    </Sidebar>
    <Sidebar v-model:visible="editHolder" :baseZIndex="1000" position="right" :style="'width:70%'" class="custombar">
      <h3>{{ currentItem ? 'Editar titular' : 'Nuevo titular' }}</h3>
      <holder ref="holderForm" :holder="holderData" :edit="true" :typeEmployees=typeEmployees :typeHousing=typeHousing
              @cancel="editHolder = false" @ok="getData"></holder>
      <div class="p-grid p-jc-end">
        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="editHolder = false"/>
        <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveHolder"/>
      </div>
    </Sidebar>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>
<script src="./EnrollmentList.js">
</script>
<style src="./EnrollmentList.scss" lang="scss" scoped></style>
