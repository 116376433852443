import bouncer from "../../../../../helpers/bouncer";
import holderService from '../../../service/holder.service'
import service from '../../../service/enrollment.service'
import {defineAsyncComponent} from "vue";
import {mapState} from "vuex";
import catalogService from "../../../../core/service/catalog.service";

export default {
    mixins: [bouncer],
    components: {
        holder: defineAsyncComponent(() => import('../../holders/Holder')),
        enrollment: defineAsyncComponent(() => import('./../Enrollment')),
        students: defineAsyncComponent(() => import('../../students/StudentList')),
        contract: defineAsyncComponent(() => import('../../contracts/Contract')),
        payments: defineAsyncComponent(() => import('../../payments/PaymentList')),
        documents: defineAsyncComponent(() => import('../../documents/DocEnrollmentList'))

    },
    data() {
        return {
            loading: false,
            paymentsSidebar: false,
            docsSidebar: false,
            editHolder: false,
            studentSidebar: false,
            contractSidebar: false,
            deleteDialog: false,
            cloneDialog: false,
            editDialog: false,
            studentDialog: false,
            holderData: {},
            enrollments: [],
            filters: {},
            currentItem: null,
            typeEmployees: [],
            typeHousing: [],
        }
    },

    mounted() {
        if (this.canAccess('contratos_read')) {
            if (this.$route.params.id) {
                this.loading = true;
                const id = this.$route.params.id;
                holderService.get(id).then(x => {
                    this.holderData = x.data;
                    if (!this.country) {
                        if (this.holderData.country) {
                            this.$store.dispatch('enroll/setCountry', this.holderData.country);
                        } else {
                            this.$router.push('/verification/holders')
                        }
                    }
                    catalogService.get('holders/types/employee').then(x => {
                        this.typeEmployees = [];
                        Object.keys(x.data).forEach(k => {
                            this.typeEmployees.push({value: k, label: x.data[k]});
                        });
                        this.typeEmployees = Object.values(this.typeEmployees)
                    });
                    catalogService.get('holders/types/housing').then(x => {
                        this.typeHousing = [];
                        Object.keys(x.data).forEach(k => {
                            this.typeHousing.push({value: k, label: x.data[k]});
                        });
                    });
                    this.getData();
                    this.loading = false
                });
            }
        }

    },
    methods: {
        onSelectedFiles(event) {
            for (let file of event.files) {
                const reader = new FileReader();
                reader.onload = () => {
                };
                reader.readAsDataURL(file);
            }
        },
        cancelDialog(){
            location.reload();
        },
        canMigrate(data) {
            if (data.employee?.name == 'ASESOR IMPORTACION') {
                return true
            }
            return (data.payments.reduce((accumulator, current) => {
                return (current.status == 'Aprobado') ? accumulator + current.value_paid : accumulator + 0;
            }, 0) + data.value_transition) >= data.cost
        },

        saveHolder() {
            this.$refs.holderForm.$refs.holderForm.save().then(() => {
                this.getData()
            })
        },
        editStudent(ev) {
            if (ev.balance - ev.value_transition <= 0) {
                this.currentItem = ev;
                this.$nextTick(() => {
                    this.studentSidebar = true;
                })
            } else {
                if (ev.balance > 0 && ev.employee?.name != 'ASESOR IMPORTACION') {
                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Pagos requeridos',
                        detail: 'El valor total de la matrícula no ha sido registrado',
                        life: this.$utils.toastLifeTime()
                    });
                } else {
                    this.currentItem = ev;
                    this.$nextTick(() => {
                        this.studentSidebar = true;
                    })
                }
            }
        },
        deleteRow() {
            service.delete(this.currentItem.id).then(() => {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminado correctamente',
                    life: this.$utils.toastLifeTime()
                });
            }).finally(() => {
                this.getData();
            })
        },
        cloneRow() {
            service.clone(this.currentItem.id)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Clonado correctamente',
                        life: this.$utils.toastLifeTime()
                    });
                })
                .catch((err) => {
                    const message = err.response.data;
                    let error = this.$utils.formatError(message);
                    this.$toast.add({
                        severity: "error",
                        summary: "Error",
                        detail: error,
                        life: this.$utils.toastLifeTime(),
                    });
                })
                .finally(() => {
                    this.getData();
                })
        },
        edit(val) {
            this.currentItem = val;
            this.$nextTick(() => {
                this.editDialog = true;
            })

        },
        doAddDocs(enrollInfo) {
           if (enrollInfo.status==='NBS3'){
               this.$toast.add({
                   severity: 'warn',
                   summary: 'Estado Documentos',
                   detail: 'La matrícula actualmente se encuentra en estado NBS3 los documentos ya no se pueden eliminar',
                   life: this.$utils.toastLifeTime()
               });
           }
            console.log(enrollInfo)
            this.currentItem = enrollInfo;
            this.$nextTick(() => this.docsSidebar = true).then();
        },
        doPayments(val) {
            this.$store.dispatch('enroll/setBalance', val.balance);
            this.currentItem = val;
            this.$nextTick(() => this.paymentsSidebar = true).then();
        },
        isContractAvailable() {
            return this.currentItem ? (this.currentItem.students.length > 0 || (this.currentItem.is_holder_student === 'y' || this.currentItem.is_holder_student === true)) : false;
        },
        doContract(val) {
            this.currentItem = val;
            if (this.isContractAvailable()) {
                this.$nextTick(() => {
                    this.contractSidebar = true
                })
            } else {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Datos incompletos',
                    detail: 'Verifique los beneficiarios.',
                    life: this.$utils.toastLifeTime()
                });
            }

        },
        confirmDelete(val) {
            this.currentItem = val;
            this.deleteDialog = true;
        },
        confirmClone(val) {
            this.currentItem = val;
            this.cloneDialog = true;
        },
        saveEnroll() {
            const ev = this.$refs.enrollForm.$refs.enrollForm.save();
            if (!ev) {
                return
            }
            ev.then(() => {
                this.getData()
            }).catch(() => {
                this.loading = false
            })
        },
        getData() {
            this.loading = true;
            this.editDialog = false;
            this.deleteDialog = false;
            this.cloneDialog = false;
            this.studentDialog = false;
            this.currentItem = null;
            service.getByHolder(this.holderData.id).then(x => {
                this.enrollments = x.data;
            }).catch(() => {
                this.enrollments = [];
            }).finally(() => {
                this.loading = false;
            })
        },
    },
    computed: {
        ...mapState('enroll', ['country']),
        ...mapState('auth', ['isAdmin']),
    }
}
